<!--
--------------------------------------------------------------------------
   SectionGraph.vue
--------------------------------------------------------------------------

    Component to build a data graph in a section

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div :id="'section-' + section.id + '-graph'" class="section-graph mb-5" v-if="loading === 0 && templatesLoaded">
        <div class="row" v-if="section.graph === 1">
            <div class="col-12 col-md-4">
                <highcharts :options="chartOptions1"></highcharts>
            </div>
            <div class="col-12 col-md-8">
                <highcharts :options="chartOptions2"></highcharts>
            </div>
        </div>
        <div class="row" v-if="section.graph === 2">
            <div class="col-12">
                <highcharts :options="chartOptions3"></highcharts>
            </div>
        </div>
        <div class="row" v-if="section.graph === 3">
            <div class="col-12">
                <highcharts :options="chartOptions4"></highcharts>
            </div>
        </div>
        <div class="row" v-if="section.graph === 4">
            <div class="col-12">
                <highcharts :options="chartOptions5"></highcharts>
            </div>
        </div>
    </div>
</template>

<script>
    import { Chart } from 'highcharts-vue';
    import exportingInit from "highcharts/modules/exporting";
    import exportDataInit from "highcharts/modules/export-data";
    import Highcharts from "highcharts";

    exportingInit(Highcharts);
    exportDataInit(Highcharts);

    export default {
        name: 'section-graph',
        props: ['section'],
        components: { highcharts: Chart },
        data() {
            return {
                loading: -2,
                templatesLoaded: false,
                formData: {
                    query: '*:*',
                    facets: [],
                    page: {
                        pageIdx: 0,
                        pageSize: 1
                    }
                },
                lang: {
                    resetZoom: this.$gettext('View all'),
                    resetZoomTitle: this.$gettext('View all'),
                    decimalPoint: ',',
                    thousandsSep: '.',
                    contextButtonTitle: this.$gettext('Menu'),
                    downloadJPEG: this.$gettext('Download JPEG'),
                    downloadPDF: this.$gettext('Download PDF'),
                    downloadPNG: this.$gettext('Download PNG'),
                    downloadSVG: this.$gettext('Download SVG'),
                    loading: this.$gettext('Loading...'),
                    printChart: this.$gettext('Print'),
                    downloadCSV: this.$gettext('Download CSV'),
                    downloadXLS: this.$gettext('Download XLS (Excel)'),
                    viewData: this.$gettext('View data table'),
                    viewFullscreen: this.$gettext('View in full screen'),
                    hideData: this.$gettext('Hide data table'),
                    exitFullscreen: this.$gettext('Exit from full screen'),
                    numericSymbols: null
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["printChart",
                                "separator",
                                "downloadPNG",
                                "downloadJPEG",
                                "downloadPDF",
                                "downloadSVG",
                                "separator",
                                "downloadCSV",
                                "downloadXLS",
                                //"viewData",
                                "openInCloud"]
                        }
                    }
                },
                chartOptions1: {
                    chart: {
                        type: 'column',
                        style: {
                            fontFamily: '"Roboto", Helvetica, serif'
                        }
                    },
                    legend: {
                        enabled: true,
                        layout: 'vertical',
                        align: 'center',
                        verticalAlign: 'bottom',
                        borderWidth: 1,
                        backgroundColor: '#FFFFFF',
                        shadow: true
                    },
                    title: {
                        text: this.section.fullName[this.$language.current] + ' ' + this.$gettext('by type')
                    },
                    xAxis: {
                        title: {
                            text: this.$gettext('Activity type')
                        },
                        labels: {
                            enabled: false
                        },
                        categories: [
                            this.$gettext('Activity type'),
                        ],
                    },
                    yAxis: {
                        min: 0,
                        allowDecimals: false,
                        title: {
                            text: this.$gettext('Num. activities')
                        }
                    },
                    tooltip: {
                        headerFormat: '',
                        formatter: function() {
                            return this.series.name + ': <b>' + Highcharts.numberFormat(this.y, -1, ',', '.') + '</b>';
                        }
                    },
                    plotOptions: {
                        series: {
                            dataLabels: {
                                enabled: true,
                                rotation: -90,
                                align: 'left',
                                x: 0,
                                y: -3,
                                style: {
                                    fontSize: '12px',
                                    textShadow: 'none'
                                },
                                formatter: function () {
                                    return Highcharts.numberFormat(this.y, -1, ',', '.');
                                }
                            },
                            pointPadding: 0.1,
                            groupPadding: 0,
                            cursor: 'pointer',
                            point: {
                                events: {
                                    click: (e) => {
                                        this.$router.push({ path: this.buildRoute([{ parameterName: 'as', value: this.UTF8ToB64(e.point.series.userOptions.value) }, { parameterName: 'p', value: '' }]) });
                                    }
                                }
                            },
                            maxPointWidth: '20'
                        }
                    },
                    series: []
                },
                chartOptions2: {
                    chart: {
                        type: 'column',
                        style: {
                            fontFamily: '"Roboto", Helvetica, serif'
                        },
                        zoomType: 'x',
                        events: {
                            load: function () {
                                    this.xAxis[0].isDirty = true;
                                var points = this.series[0].points;
                                if (points.length < 20) return;
                                var min = points[points.length - 20].x;
                                var max = points[points.length - 1].x;
                                this.xAxis[0].setExtremes(min, max);
                                this.redraw();
                            }
                        },
                    },
                    title: {
                        text: this.section.fullName[this.$language.current] + ' ' + this.$gettext('by date')
                    },
                    xAxis: {
                        categories: [],
                        labels: {
                            rotation: -90,
                            align: 'right',
                            x: 4,
                            y: 17
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: this.$gettext('Num. activities')
                        },
                        allowDecimals: false,
                        stackLabels: {
                            enabled: true,
                            rotation: -90,
                            align: 'center',
                            x: 8,
                            y: 20,
                            style: {
                                fontSize: '12px',
                                textShadow: 'none'
                            },
                            formatter: function() {
                                return Highcharts.numberFormat(this.total, -1, ',', '.');
                            }
                        }
                    },
                    legend: {
                        enabled: true,
                        layout: 'vertical',
                        align: 'center',
                        verticalAlign: 'bottom',
                        borderWidth: 1,
                        backgroundColor: '#FFFFFF',
                        shadow: true
                    },
                    tooltip: {
                        formatter: function () {
                            return '<b>' + this.x + '</b><br/>' +
                                this.series.name + ': ' + Highcharts.numberFormat(this.y, -1, ',', '.') + '<br/>' +
                                'Total: ' + Highcharts.numberFormat(this.point.stackTotal, -1, ',', '.');
                        }
                    },
                    plotOptions: {
                        column: {
                            stacking: 'normal',
                            groupPadding: 0,
                            cursor: 'pointer',
                            point: {
                                events: {
                                    click: (e) => {
                                        this.$router.push({ path: this.buildRoute([{ parameterName: 'as', value: this.UTF8ToB64(e.point.series.userOptions.value) }, { parameterName: 'ad', value: this.UTF8ToB64(e.point.category) }, {parameterName: 'p', value: ''}]) });
                                    }
                                }
                            },
                            maxPointWidth: '20'
                        }
                    },
                    series: []
                },
                chartOptions3: {
                    chart: {
                        type: 'column',
                        style: {
                            fontFamily: '"Roboto", Helvetica, serif'
                        },
                        zoomType: 'x',
                        events: {
                            load: function() {
                                var points = this.series[0].points;
                                if (points.length < 20) return;
                                var min = points[points.length - 20].x;
                                var max = points[points.length - 1].x;
                                this.xAxis[0].setExtremes(min, max);
                            }
                        }
                    },
                    title: {
                        text: this.section.fullName[this.$language.current] + ' ' + this.$gettext('by date')
                    },
                    xAxis: {
                        categories: [],
                        labels: {
                            rotation: -90,
                            align: 'right',
                            x: 4,
                            y: 17
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: this.$gettext('Num. activities')
                        },
                        allowDecimals: false,
                        stackLabels: {
                            enabled: true,
                            style: {
                                fontWeight: 'bold',
                                fontSize: '12px',
                                textShadow: 'none'
                            }
                        }
                    },
                    legend: {
                        enabled: true,
                        layout: 'vertical',
                        align: 'center',
                        verticalAlign: 'bottom',
                        borderWidth: 1,
                        backgroundColor: '#FFFFFF',
                        shadow: true
                    },
                    tooltip: {
                        formatter: function() {
                            return '<b>'+ this.x +'</b><br/>'+
                                this.series.name +': '+ this.y +'<br/>'+
                                'Total: '+ this.point.stackTotal;
                        }
                    },
                    plotOptions: {
                        column: {
                            stacking: 'normal',
                            groupPadding: 0,
                            cursor: 'pointer',
                            point: {
                                events: {
                                    click: (e) => {
                                        if (e.point.series.userOptions.value) this.$router.push({ path: this.buildRoute([{ parameterName: 'aao', value: this.UTF8ToB64(e.point.series.userOptions.value) }, { parameterName: 'ad', value: this.UTF8ToB64(e.point.category) }, {parameterName: 'p', value: ''}]) });
                                    }
                                }
                            },
                            maxPointWidth: '20'
                        }
                    },
                    series: []
                },
                chartOptions4: {
                    chart: {
                        type: 'column',
                        style: {
                            fontFamily: '"Roboto", Helvetica, serif'
                        },
                        zoomType: 'x',
                        events: {
                            load: function() {
                                var points = this.series[0].points;
                                if (points.length < 20) return;
                                var min = points[points.length - 20].x;
                                var max = points[points.length - 1].x;
                                this.xAxis[0].setExtremes(min, max);
                            }
                        }
                    },
                    title: {
                        text: this.section.fullName[this.$language.current] + ' ' + this.$gettext('by date')
                    },
                    xAxis: {
                        categories: [],
                        labels: {
                            rotation: -90,
                            align: 'right',
                            x: 4,
                            y: 17
                        }
                    },
                    yAxis: {
                        min: 0,
                        allowDecimals: false,
                        title: {
                            text: this.$gettext('Num. activities')
                        }
                    },
                    plotOptions: {
                        column: {
                            groupPadding: 0,
                            cursor: 'pointer',
                            point: {
                                events: {
                                    click: (e) => {
                                        this.$router.push({ path: this.buildRoute([{ parameterName: 'ad', value: this.UTF8ToB64(e.point.category) }, {parameterName: 'p', value: ''}]) });
                                    }
                                }
                            }
                        }
                    },
                    legend: {
                        enabled: true,
                        layout: 'vertical',
                        align: 'center',
                        verticalAlign: 'bottom',
                        borderWidth: 1,
                        backgroundColor: '#FFFFFF',
                        shadow: true
                    },
                    tooltip: {
                        pointFormat: this.$gettext('Num. activities') + ': <b>{point.y}</b>',
                    },
                    series: [{
                        name: this.$gettext('Num. activities'),
                        data: [],
                        color: '#DD3E3E',
                        dataLabels: {
                            enabled: true,
                            rotation: -90,
                            align: 'left',
                            x: 2,
                            y: -3,
                            style: {
                                fontSize: '12px',
                                textShadow: 'none'
                            }
                        },
                        maxPointWidth: '20'
                    }]
                },
                chartOptions5: {
                    chart: {
                        type: 'column',
                        style: {
                            fontFamily: '"Roboto", Helvetica, serif'
                        },
                        zoomType: 'x',
                        events: {
                            load: function() {
                                this.xAxis[0].isDirty = true;
                                var points = this.series[0].points;
                                if (points.length < 20) return;
                                var min = points[points.length - 20].x;
                                var max = points[points.length - 1].x;
                                this.xAxis[0].setExtremes(min, max);
                                this.redraw();
                            }
                        }
                    },
                    title: {
                        text: this.section.fullName[this.$language.current] + ' ' + this.$gettext('by date')
                    },
                    xAxis: {
                        categories: [],
                        labels: {
                            rotation: -90,
                            align: 'right',
                            x: 4,
                            y: 17
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: this.$gettext('Num. activities')
                        },
                        allowDecimals: false,
                        stackLabels: {
                            enabled: true,
                            rotation: -90,
                            align: 'center',
                            x: 3,
                            y: 19,
                            style: {
                                fontSize: '12px',
                                textShadow: 'none'
                            },
                            formatter: function() {
                                return Highcharts.numberFormat(this.total, -1, ',', '.');
                            }
                        }
                    },
                    legend: {
                        enabled: true,
                        layout: 'vertical',
                        align: 'center',
                        verticalAlign: 'bottom',
                        borderWidth: 1,
                        backgroundColor: '#FFFFFF',
                        shadow: true
                    },
                    tooltip: {
                        formatter: function() {
                            return '<b>'+ Highcharts.numberFormat(this.x, -1, ',', '.') +'</b><br/>'+
                                this.series.name +': '+ Highcharts.numberFormat(this.y, -1, ',', '.') +'<br/>'+
                                'Total: '+ Highcharts.numberFormat(this.point.stackTotal, -1, ',', '.');
                        }
                    },
                    plotOptions: {
                        column: {
                            stacking: 'normal',
                            groupPadding: 0,
                            cursor: 'pointer',
                            point: {
                                events: {
                                    click: (e) => {
                                        this.$router.push({ path: this.buildRoute([{ parameterName: 'as', value: this.UTF8ToB64(e.point.series.userOptions.value) }, { parameterName: 'ad', value: this.UTF8ToB64(e.point.category) }, {parameterName: 'p', value: ''}]) });
                                    }
                                }
                            },
                            maxPointWidth: '20'
                        }
                    },
                    series: []
                }
            }
        },
        mounted() {
            // Check if item templates are loaded yety
            if (this.$store.state.templatesLoaded) {
                this.templatesLoaded = true;
                this.init();
            } else {
                // Prepare event to detect templates are loaded
                let vm = this;
                this.$root.$on('templatesLoaded', function () {
                    vm.init();
                    vm.templatesLoaded = true;
                });
            }
        },
        methods: {
            /**
             * Inits graph
             */
             init() {
                // Get data related to section graph type
                if (this.section.graph === 1) {
                    this.chartOptions1.title.text = this.section.fullName[this.$language.current] + ' ' + this.$gettext('by type');
                    this.chartOptions2.title.text = this.section.fullName[this.$language.current] + ' ' + this.$gettext('by date');
                    this.chartOptions1.series = [];
                    this.chartOptions2.xAxis.categories = [];
                    this.chartOptions2.series = [];
                    this.chartOptions1.lang = this.lang;
                    this.chartOptions2.lang = this.lang;
                    this.chartOptions1.exporting = this.exporting;
                    this.chartOptions2.exporting = this.exporting;
                    this.getDataBySubtype();
                    this.getDataBySubtypeByYear();
                } else if (this.section.graph === 2) {
                    this.chartOptions3.title.text = this.section.fullName[this.$language.current] + ' ' + this.$gettext('by date');
                    this.chartOptions3.xAxis.categories = [];
                    this.chartOptions3.series = [];
                    this.chartOptions3.lang = this.lang;
                    this.chartOptions3.exporting = this.exporting;
                    this.getDataByOpenAccessByYear();
                } else if (this.section.graph === 3) {
                    this.chartOptions4.title.text = this.section.fullName[this.$language.current] + ' ' + this.$gettext('by date');
                    this.chartOptions4.xAxis.categories = [];
                    this.chartOptions4.series[0].data = [];
                    this.chartOptions4.lang = this.lang;
                    this.chartOptions4.exporting = this.exporting;
                    this.getDataByYear();
                } else if (this.section.graph === 4) {
                    this.chartOptions5.title.text = this.section.fullName[this.$language.current] + ' ' + this.$gettext('by date');
                    this.chartOptions5.xAxis.categories = [];
                    this.chartOptions5.series = [];
                    this.chartOptions5.lang = this.lang;
                    this.chartOptions5.exporting = this.exporting;
                    this.getDataBySubtypeByYearAll();
                }
             },
            /**
             * Get data for the subtypes graphic
             */
            getDataBySubtype() {
                // Prepare data to search
                this.formData.query = this.section.content[this.$language.current];
                this.formData.facets = [{
                    field: this.getFieldname('subtype', this.section.itemType) + '_facet',
                    minCount: 1,
                    order: 'index',
                    limit: -1
                }];

                // Search data
                this.axios.post(this.apiURL + '/items/search', this.formData)
                    .then(response => {
                        // Get facet data
                        let facetResponse = response.data.facets.find(rf => rf.length > 0 && rf[0].field.name === this.getFieldname('subtype', this.section.itemType) + '_facet');
                        facetResponse.forEach(frd => {
                            // Determine color
                            let color = '#E2AB17';
                            let itemTemplate = this.$store.state.defaultItemTemplates['activity'][frd.value];
                            if (itemTemplate !== undefined) color = itemTemplate.color;
                            this.chartOptions1.series.push({
                                name: this.$gettext('subtype_' + frd.value),
                                data: [frd.valueCount],
                                color: color,
                                value: frd.value
                            });
                        });

                        this.loading++;
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            /**
             * Get data for the subtypes / years graphic
             */
            getDataBySubtypeByYear() {
                // Prepare data to search
                this.formData.query = this.section.content[this.$language.current];
                this.formData.pivots = [this.getFieldname('year', this.section.itemType) + '_str', this.getFieldname('subtype', this.section.itemType) + '_str'];

                // Search data
                this.axios.post(this.apiURL + '/items/search', this.formData)
                    .then(response => {
                        // Get facet pivot data data
                        let pivotResponse = response.data.pivots[0];

                        // Get all categories (years)
                        pivotResponse.forEach(pr => {
                            if (!this.chartOptions2.xAxis.categories.includes(pr.value)) this.chartOptions2.xAxis.categories.push(pr.value);
                        });

                        // Create all series
                        pivotResponse.forEach(pr => {
                            pr.pivot.forEach(pp => {
                                // Find or add serie
                                let serie = this.chartOptions2.series.find(s => s.name === pp.value);
                                if (serie === undefined) {
                                    // Determine color
                                    let color = '#E2AB17';
                                    let itemTemplate = this.$store.state.defaultItemTemplates['activity'][pp.value];
                                    if (itemTemplate !== undefined) color = itemTemplate.color;
                                    this.chartOptions2.series.push({
                                        name: pp.value,
                                        data: Array(this.chartOptions2.xAxis.categories.length).fill(0),
                                        color: color,
                                        value: pp.value
                                    });
                                }
                            });
                        });

                        // Recover series data
                        pivotResponse.forEach(pr => {
                            pr.pivot.forEach(pp => {
                                // Find or add series
                                let serie = this.chartOptions2.series.find(s => s.name === pp.value);
                                serie.data[this.chartOptions2.xAxis.categories.findIndex(c => c === pr.value)] = pp.valueCount;
                            });
                        });

                        // Set translated name
                        this.chartOptions2.series.forEach(s => s.name = this.$gettext('subtype_' + s.name));

                        this.loading++;
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            /**
             * Get data for the open access / years graphic
             */
            getDataByOpenAccessByYear() {
                // Prepare data to search
                this.formData.query = this.section.content[this.$language.current];
                this.formData.pivots = [this.getFieldname('year', this.section.itemType) + '_str', this.getFieldname('openAccess', this.section.itemType) + '_str'];

                // Search data
                this.axios.post(this.apiURL + '/items/search', this.formData)
                    .then(response => {
                        // Get facet pivot data data
                        let pivotResponse = response.data.pivots[0];

                        // Get all categories (years)
                        pivotResponse.forEach(pr => {
                            if (!this.chartOptions3.xAxis.categories.includes(pr.value)) this.chartOptions3.xAxis.categories.push(pr.value);
                        });

                        // Create all series
                        this.chartOptions3.series.push({
                            name: this.$gettext('Open access'),
                            data: Array(this.chartOptions3.xAxis.categories.length).fill(0),
                            color: '#FFC719',
                            value: 1
                        });
                        this.chartOptions3.series.push({
                            name: this.$gettext('Closed access'),
                            data: Array(this.chartOptions3.xAxis.categories.length).fill(0),
                            color: '#624289'
                        });

                        // Recover series data
                        let serieOpen = this.chartOptions3.series.find(s => s.name === this.$gettext('Open access'));
                        let serieClosed = this.chartOptions3.series.find(s => s.name === this.$gettext('Closed access'));
                        pivotResponse.forEach(pr => {
                            serieClosed.data[this.chartOptions3.xAxis.categories.findIndex(c => c === pr.value)] = pr.valueCount;
                            pr.pivot.forEach(pp => {
                                // Add series values
                                serieOpen.data[this.chartOptions3.xAxis.categories.findIndex(c => c === pr.value)] = pp.valueCount;
                                serieClosed.data[this.chartOptions3.xAxis.categories.findIndex(c => c === pr.value)] = pr.valueCount - pp.valueCount;
                            });
                        });

                        this.loading+=2;
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            /**
             * Get data by year
             */
            getDataByYear() {
                // Prepare data to search
                this.formData.query = this.section.content[this.$language.current];
                this.formData.pivots = [this.getFieldname('year', this.section.itemType) + '_str', this.getFieldname('subtype', this.section.itemType) + '_str'];

                // Search data
                this.axios.post(this.apiURL + '/items/search', this.formData)
                    .then(response => {
                        // Get facet pivot data data
                        let pivotResponse = response.data.pivots[0];

                        // Get all categories (years)
                        pivotResponse.forEach(pr => {
                            if (!this.chartOptions4.xAxis.categories.includes(pr.value)) {
                                this.chartOptions4.xAxis.categories.push(pr.value);
                                this.chartOptions4.series[0].data.push(pr.valueCount);
                            }
                        });

                        this.loading+=2;
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            /**
             * Get data for the subtypes / years graphic
             */
            getDataBySubtypeByYearAll() {
                // Prepare data to search
                this.formData.query = this.section.content[this.$language.current];
                this.formData.pivots = [this.getFieldname('year', this.section.itemType) + '_str', this.getFieldname('subtype', this.section.itemType) + '_str'];

                // Search data
                this.axios.post(this.apiURL + '/items/search', this.formData)
                    .then(response => {
                        // Get facet pivot data data
                        let pivotResponse = response.data.pivots[0];

                        // Get all categories (years)
                        pivotResponse.forEach(pr => {
                            if (!this.chartOptions5.xAxis.categories.includes(pr.value)) this.chartOptions5.xAxis.categories.push(pr.value);
                        });

                        // Create all series
                        pivotResponse.forEach(pr => {
                            pr.pivot.forEach(pp => {
                                // Find or add serie
                                let serie = this.chartOptions5.series.find(s => s.name === pp.value);
                                if (serie === undefined) {
                                    // Determine color
                                    let color = '#E2AB17';
                                    let itemTemplate = this.$store.state.defaultItemTemplates['activity'][pp.value];
                                    if (itemTemplate !== undefined) color = itemTemplate.color;
                                    this.chartOptions5.series.push({
                                        name: pp.value,
                                        data: Array(this.chartOptions5.xAxis.categories.length).fill(0),
                                        color: color,
                                        value: pp.value
                                    });
                                }
                            });
                        });

                        // Recover series data
                        pivotResponse.forEach(pr => {
                            pr.pivot.forEach(pp => {
                                // Find or add series
                                let serie = this.chartOptions5.series.find(s => s.name === pp.value);
                                serie.data[this.chartOptions5.xAxis.categories.findIndex(c => c === pr.value)] = pp.valueCount;
                            });
                        });

                        // Set translated name
                        this.chartOptions5.series.forEach(s => s.name = this.$gettext('subtype_' + s.name));

                        this.loading+=2;
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            }
        },
        watch: {
            section() {
                this.loading = -2;
                this.init();
            }
        }
    };
</script>