<!--
--------------------------------------------------------------------------
   SectionText.vue
--------------------------------------------------------------------------

    Component to build section text view

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div :id="'section' + section.id" class="section-text view">
        <h1>{{ section.fullName[$language.current] }}</h1>
        <div class="text" v-html="section.content[$language.current]"></div>
    </div>
</template>

<script>
    export default {
        name: 'section-text',
        props: ['section'],
        mounted() {
            this.init();
        },
        methods: {
            /**
             * Inits data
             */
            init() {
                // Set breadcrumbs
                this.$store.commit('setBreadcrumbs', [{ text: this.$gettext('Home'), to: '/' }, { text: this.section.fullName[this.$language.current], active: true }]);

                // Set current section
                this.$store.commit('setCurrentSection', this.section);

                // Set metadata
                this.setMetadata(this.section.fullName[this.$language.current] + ' | ' + this.$gettext(this.$store.state.metadata.title), this.decodeHTML(this.section.content[this.$language.current].substring(0, 200)), this.section.path[this.$language.current].replace('_', ' ') + ',' + this.$gettext(this.$store.state.metadata.keywords));
            }
        },
        watch: {
            section() {
                this.init();
            }
        }
    };
</script>