<!--
--------------------------------------------------------------------------
   SectionItem.vue
--------------------------------------------------------------------------

    Component to build section of items view

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div :id="'section-' + section.id" class="section-item view">
        <h1>
            {{ section.fullName[$language.current] }}
            <b-button squared variant="outline-secondary" size="sm" class="info-button" v-b-modal.info-modal v-if="section.info !== undefined">
                <i class="fas fa-info"></i>
            </b-button>
        </h1>
        <b-alert variant="danger" show v-if="section.warningMessage != null"><span v-html="section.warningMessage[$language.current]" class="hide-p"></span></b-alert>
        <sectionGraph :section="section" v-if="section.graph !== undefined && showGraph" ref="section-graph"></sectionGraph>
        <items :section="section" :item="null" :searchType="null" :simulation="null" :showFeatured="false" :featuredDefault="false" :showTitle="false"></items>
        <b-modal id="info-modal" :title="'Information'|translate" v-if="section.showInfo === true">
            <p class="my-4" v-html="section.info[$language.current]"></p>
        </b-modal>
    </div>
</template>

<script>
    import Items from '@/components/Items';
    import SectionGraph from '@/components/SectionGraph';

    export default {
        name: 'section-item',
        props: ['section'],
        components: { Items, SectionGraph },
        data() {
            return {
                showGraph: false
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            /**
             * Inits data
             */
            init() {
                // Set search values
                this.$store.commit('changeSearchSection', { section: this.section, text: this.section.fullName[this.$language.current] });

                // Set breadcrumbs
                this.$store.commit('setBreadcrumbs', [{ text: this.$gettext('Home'), to: '/' }, { text: this.section.fullName[this.$language.current], active: true }]);

                // Set current section
                this.$store.commit('setCurrentSection', this.section);

                // Set metadata
                this.setMetadata(this.section.fullName[this.$language.current] + ' | ' + this.$gettext(this.$store.state.metadata.title), this.decodeHTML(this.section.info[this.$language.current].substring(0, 200)), this.section.path[this.$language.current].replace('_', ' ') + ',' + this.$gettext(this.$store.state.metadata.keywords));
            }
        },
        watch: {
            section() {
                this.init();
            }
        }
    };
</script>